<!-- import Vue from 'vue/types/umd'; -->
<template>
  <div>
    <div class="player container" v-if="info.vid" id="container"></div>
    <div class="rich-text" v-html="content"></div>
    <!-- <div class="fav-detail"
         @click="toFav">
      <img v-if="info.is_favourite==0"
           class="fav-icon"
           src="@/assets/images/icon-fav.svg" />
      <img v-if="info.is_favourite==1"
           class="fav-icon"
           src="@/assets/images/icon-fav-a.svg" />
    </div> -->
  </div>
</template>

<script>
import { fetchDetail, toFavourite } from "@/network/API";
import { Base64 } from "js-base64";
import "../utils/superplayer.js";
export default {
  data() {
    return {
      info: {},
      content: "",
      player: null,
    };
  },
  created() {
    if (this.$route.params.id) {
      this.getDetail(this.$route.params.id);
    }
  },
  updated() {
    if (this.info.vid && this.info.vid.length > 0) {
      const player = new SuperPlayer({
        container: ".container",
      });
      player.play({ vid: this.info.vid });
    }
  },
  methods: {
    async getDetail(aid) {
      let data = await fetchDetail(aid);
      this.content = Base64.decode(data.content);
      this.content = this.content.replace(
        /<img/g,
        "<img style='max-width:100%;height:auto;'"
      );
      this.info = data;
      this.$store.commit("updateTitle", {
        showBar: true,
        pushed: true,
      });
      this.$store.commit("updateNavTitle", {
        navTitle: data.title,
      });
    },
    async toFav() {
      let data = await toFavourite({
        id: this.info.article_id,
      });
    },
  },
};
</script>

<style>
.rich-text {
  box-sizing: border-box;
  width: 100%;
  padding: 16px;
  font-size: 18px;
  line-height: 30px;
}
.rich-text img {
  margin: 10px auto;
  width: 100%;
  display: block;
}
.player {
  height: 10rem;
}
.fav-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  margin: 0 auto;
}
.fav-detail img {
  width: 100px;
  height: 100px;
}
</style>
